<template>
  <v-progress-circular
    indeterminate
    class="mr-2"
    color="grey"
    size="15"
    width="2"
  ></v-progress-circular>
</template>

<script>

export default {
  name: 'PreloaderCircular'
}

</script>
